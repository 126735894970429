<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
        <div class="box-ui-check searchbar-content mr-5">
          <div class="title"><span>{{ $t('searchArea.regDate') }}</span></div>
          <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
        </div>
      </div>
      <div class="searchBar">
        <div class="box-ui-check searchbar-content mr-5">
          <div class="title"><span>{{ $t('searchArea.blockdays') }}</span></div>
          <select v-model="reqData.blockTerm">
            <option value="">{{ $t('searchArea.total') }}</option>
            <option value="0">{{ $t('searchArea.perpetual') }}</option>
            <option value="7">{{ $t('searchArea.sevendays') }}</option>
            <option value="30">{{ $t('searchArea.monthly') }}</option>
          </select>
        </div>
        <div class="box-ui-check searchbar-content mr-5">
          <div class="title"><span>{{ $t('searchArea.blockkind') }}</span></div>
          <select v-model="reqData.blockType">
            <option value="">{{ $t('searchArea.total') }}</option>
            <option value="IP">{{ $t('searchArea.ipAddress') }}</option>
            <option value="TEL">{{ $t('company.contact') }}</option>
            <option value="ACC">{{ $t('searchArea.accountN') }}</option>
          </select>
        </div>
        <div class="box-ui-check searchbar-content mr-5">
          <div class="title"><span>{{ $t('searchArea.includeDel') }}</span></div>
          <input type="checkbox" class="set-switch" v-model="reqData.delYn" />
        </div>
        <div class="box-ui-check searchbar-content mr-5">
          <div class="title"><span>{{ $t('searchArea.blocklist') }}</span></div>
          <input type="text" v-model="reqData.blockTarget" />
        </div>
        <div class="box-ui-check searchbar-content mr-5">
          <div class="title"><span>{{ $t('common.id') }}</span></div>
          <div>
            <input type="text" v-model="reqData.regId" />
            <button class="btn-search ml-5" type="button" @click="pageSeach">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value" >{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
        <button type="button" class="btn-layout btn-grey" @click="detailModalOpen()">{{ $t('button.add') }}</button>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
          <template v-if="tableData.length !== 0">
            <tr v-for="(item, idx) in tableData" :key="item.blockIdx">
              <td class="roboto">{{ idx + 1 }}</td>
              <td>{{ item.blockTypeName }}</td>
              <td>{{ item.blockTarget }}</td>
              <td>{{ item.blockTerm === "0" ? "영구" : item.blockTerm + "일" }}</td>
              <td class="roboto">{{ item.regId }}</td>
              <td class="roboto">{{ item.regDate }}</td>
              <td style="width: 5%">
                <div class="status-change-btn-wrap">
                  <button class="btn-innerTable btn-status-change wait" type="button" @click="listDel(item)">{{ $t('button.del') }}</button>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <td colspan="7">{{ $t('txt.noData') }}</td>
          </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />

    <div class="modal-wrapper" v-if="updModalActive">
      <div class="modal-wrap">
        <article>
          <div class="sub-title">
            <h3>{{ $t('button.add') }}</h3>
          </div>
          <div class="page-content">
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 10%">{{ $t('table.head.blockkind') }}</div>
                <div class="item-content">
                  <select v-model="saveData.blockType">
                    <option value="IP">{{ $t('table.head.ip') }}</option>
                    <option value="TEL">{{ $t('company.contact') }}</option>
                    <option value="ACC">{{ $t('searchArea.accountN') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 10%">{{ $t('table.head.blockdays') }}</div>
                <div class="item-content">
                  <select v-model="saveData.blockTerm">
                    <option value="0">{{ $t('searchArea.perpetual') }}</option>
                    <option value="7">{{ $t('searchArea.sevendays') }}</option>
                    <option value="30">{{ $t('searchArea.monthly') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 10%">{{ $t('table.head.blocklist') }}</div>
                <div class="item-content">
                  <input type="text" v-model="saveData.blockTarget" />
                </div>
              </div>
            </div>
            <div class="modal-btns">
              <a @click="blockSave">{{ $t('button.save') }}</a>
              <a @click="modalClose">{{ $t('button.close') }}</a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from 'lodash'
import TableHead from '@/components/main/table/Head.vue'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import { getSiteData } from '@/libs/auth-helper'
import { replaceDateT, getDateStr } from '@/libs/utils.js'
import { settingBlock, settingBlockSave } from '@/api/setting.js'
import Memo from '@/components/common/memo'
export default {
  name: 'CompanyBlockType',
  components: {
    TableHead,
    Pagination,
    DateSelector,
    Memo
  },
  data () {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['index', 'blockkind', 'blocklist', 'blockdays', 'master', 'blocktimes', 'option']
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        blockType: '',
        blockTerm: '',
        blockTarget: '',
        startDate: '',
        endDate: '',
        regId: '',
        delYn: false
      },
      saveData: {
        siteId: '',
        blockType: 'IP',
        blockTarget: '',
        blockTerm: '0'
      },
      tableData: [],
      pageInfo: {
        tatal_list_count: 0,
        page: 0,
        tatal_page_count: 0
      },
      totalInfo: {},
      siteInfo: {},
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      updModalActive: false
    }
  },
  methods: {
    maxDate () {
      const today = new Date()
      today.setHours(24, 0, 0)
      return today
    },
    detailModalOpen () {
      this.updModalActive = true
    },
    modalClose () {
      this.updModalActive = false
    },
    async pageSeach () {
      await this.setTableData(1)
    },
    async blockSave () {
      const req = this.saveData
      req.siteId = this.siteInfo.siteId
      const res = await settingBlockSave(req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('추가 완료')
        this.setTableData()
        this.modalClose()
      } else {
        alert('추가 실패, 다시 시도해주세요.')
      }
    },
    async listDel (item) {
      const req = {
        blockIdx: item.blockIdx,
        delYn: 'Y'
      }
      const res = await settingBlockSave(req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('삭제 완료')
        this.setTableData()
      } else {
        alert('삭제 실패, 다시 시도해주세요.')
      }
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    setStartDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    async getBlockList (page) {
      const req = { ...this.reqData }
      req.page = page
      if (req.delYn) {
        req.delYn = 'Y'
      } else {
        req.delYn = ''
      }
      const res = await settingBlock(req)
      return await res.data
    },
    async setTableData (page) {
      const data = await this.getBlockList(page)
      this.tableData = data.list

      for (const item of data.list) {
        if (item.delYn === 'Y') {
          item.delYn = true
        } else {
          item.delYn = false
        }
        item.regDate = replaceDateT(item.regDate)
        switch (item.blockType) {
          case 'TEL':
            item.blockTypeName = '연락처'
            break
          case 'IP':
            item.blockTypeName = '아이피'
            break
          case 'ACC':
            item.blockTypeName = '계좌'
            break
        }
      }

      if (data.pageInfo) {
        data.pageInfo.tatal_page_count = Math.ceil(Number(data.pageInfo.tatal_list_count) / 30)
        this.pageInfo = data.pageInfo
      }

      console.log(this.tableData)
      console.log(this.pageInfo)
    }
  },
  async created () {
    // this.setStartDate();
    // this.setEndDate();
    this.siteInfo = this.getSiteInfo()
    this.setTableData(1)
  }
}
</script>

<style scoped>
.item-row-container .item-wrap{gap: 20px;}
.item-row-container .item-wrap .item-title {width: 30%}
.item-row-container .item-wrap > div{padding-left: 0;}
.sub-title {
  color: #646464;
  font-size: 13px;
  padding-bottom: 11px;
  border-bottom: 2px solid #959595;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
textarea {
  width: 100%;
  min-height: 160px;
  border: 1px solid #18698b;
  resize: none;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 3px;
}
.modal-wrapper {
  display: flex;
}
</style>
